import React from "react"
import Layout from "../components/layout"
import Card from "../components/card"
import { Helmet } from "react-helmet"

export default () => (
  <Layout>
    <Helmet>
      <meta
        name="google-site-verification"
        content="hJ3XSoUIsMxmpFtkGUwruDf_CP79KOBrYWomNDt7Xb0"
      />
    </Helmet>
    <Card
      src={require("../images/portfolio/websites800.jpg")}
      alt="Web Development"
      name="Web Development"
      className="card-r"
    ></Card>
    <Card
      src={require("../images/portfolio/imagine-yourself.jpg")}
      alt="Graphic Design"
      name="Graphic Design"
      className="card"
    ></Card>
    <Card
      src={require("../images/portfolio/hearttree.jpg")}
      alt="Painting"
      name="Painting"
      className="card-r"
    ></Card>
    <Card
      src={require("../images/portfolio/AI-character.jpg")}
      alt="Illustration"
      name="Illustration"
      className="card"
    ></Card>
  </Layout>
)
