import React from 'react'

const Card = props => {
    return (
        <div className={props.className}>
            <div className="image-container"><img src={props.src} alt={props.alt} className="image" /></div>
            <div className="card-content">
            <h1>{props.name}</h1>
            <p>{props.children}</p>
            </div>
        </div>
    )
}

export default Card
